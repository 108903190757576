<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-card

        >
          <b-form
                  @submit.prevent="searchByCondition"
                  @reset.prevent="resetCondition"
          >
            <b-row>
              <!-- 查询条件     -->
              <!--日期范围-->
              <b-col md="4" cols="12">
                <xy-input-button label="日期范围"
                                 type="month"
                                 v-on:change="fromChildren($event,['searchTime'])"
                                 :params="['searchTime']"
                                 :value="state.condition.searchTime"
                >
                </xy-input-button>
              </b-col>

              <!--     平台         -->
              <b-col
                      cols="12"
                      md="4"
                      class="mb-2"
              >
                <xy-input-button
                        label="平台"
                        type="input"
                        v-on:change="fromChildren($event,['channel_name','channel_id'])"
                        :params="['channel_name','channel_id']"
                        :value="state.condition.channel_name"
                        modalName="渠道方"
                        :storeId="state.condition.store_id"
                        placeholder="点击选择平台 "
                >
                </xy-input-button>
              </b-col>

              <!--城市-->
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="城市:"
                        label-for="title"
                        label-size="sm"

                >
                  <b-form-input
                          v-model="state.condition.city_name"
                          name="title"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="城市"
                  />
                </b-form-group>
              </b-col>

              <!--     店铺         -->
              <b-col
                      cols="12"
                      md="4"
                      class="mb-2"
              >
                <xy-input-button
                        label="店铺"
                        type="input"
                        v-on:change="fromChildren($event,['store_name','store_id'])"
                        :params="['store_name','store_id']"
                        :value="state.condition.store_name"
                        :companyId="state.condition.channel_id"
                        modalName="店铺"
                        placeholder="点击选择店铺 "
                >
                </xy-input-button>
              </b-col>


              <b-col
                      cols="12"
                      md="12"
                      class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="exportData()"
                >
                  <span class="align-right">导出</span>
                </b-button>



              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-row>

          <!-- Per Page -->
          <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                    v-model="limit"
                    :options="limitOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
            <div class="demo-spacing-0">
              <b-alert
                      variant="primary"
                      show
              >
                <div class="alert-body" >
                  <span><strong >
                    {{totalAmount}}
                    <br>
                  </strong></span>
                </div>
              </b-alert>
            </div>
          </b-col>
          <b-col
                  cols="12"
                  md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                      variant="primary"
                      @click="showFixedCharge"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
            </div>
          </b-col>

        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #row-details="row">
          <!--<b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <ValidationObserver ref="observer">
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.items" :fields="fields">
                <!-- :readonly="data.item.refuse_qty>0"-->
                <template #cell(add_time)="data">
                  <div v-if="data.item.add_time === 9999 ">
                    <label> 总计：</label>
                  </div>
                  <div v-else>
                    {{toDate((data.item.add_time))}}
                  </div>

                </template>


                  <template #cell(memo)="data"  >
                    <div v-if="data.item.add_time === 9999 ">

                    </div>
                    <div v-else>
                      <b-form-input
                              id="memo"
                              size="sm"
                              v-model="data.item.memo"
                              style="width:80px"
                      />
                    </div>
                  </template>


                <template #cell(attachments)="data" >
                  <div v-if="data.item.add_time === 9999 ">

                  </div>
                  <div v-else>
                    <feather-icon icon="EditIcon" @click="showAttachments(data.item)"/>
                    <attachment-upload :theme="'preview-dropdown'"
                                       :attachment_id="'attachments'"
                                       :id="data.item.attachments"
                                       :object_type="'attachments'"
                                       :object_id="0"
                                       :readonly="true"
                                       @change=""

                    />
                  </div>


                </template>

                <template #cell(loss_charge)="data">
                  <ValidationProvider rules="twoDecimals" name="链路损失费" #default="{ errors }" >
                  <b-form-input
                          id="loss_charge"
                          size="sm"
                          v-model="data.item.loss_charge"
                          type="number"
                          style="width:80px"
                          :readonly="data.item.add_time===9999"
                  />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </template>

                <template #cell(service_order_charge)="data">
                  <b-form-input
                          id="service_order_charge"
                          size="sm"
                          v-model="data.item.service_order_charge"
                          type="number"
                          style="width:80px"
                          readonly
                  />
                </template>

                <template #cell(fine_charge)="data">
                  <b-form-input
                          id="fine_charge"
                          size="sm"
                          v-model="data.item.fine_charge"
                          type="number"
                          style="width:80px"
                          readonly
                  />

                </template>

                <template #cell(share_charge)="data">
                  <ValidationProvider rules="twoDecimals" name="共享仓扣款" #default="{ errors }" >
                  <b-form-input
                          id="share_charge"
                          size="sm"
                          v-model="data.item.share_charge"
                          type="number"
                          style="width:80px"
                          :readonly="data.item.add_time===9999"
                  />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </template>


                <template #cell(tem_charge)="data">
                  <ValidationProvider rules="twoDecimals" name="平台补款" #default="{ errors }" >
                    <b-form-input
                            id="tem_charge"
                            size="sm"
                            v-model="data.item.tem_charge"
                            type="number"
                            style="width:80px"
                            :readonly="data.item.add_time===9999"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </template>



                <template #cell(other_charge)="data">
                  <ValidationProvider rules="twoDecimals" name="其他扣款" #default="{ errors }" >
                  <b-form-input
                          id="other_charge"
                          size="sm"
                          v-model="data.item.other_charge"
                          type="number"
                          style="width:80px"
                          :readonly="data.item.add_time===9999"
                  />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </template>


                <!-- Column: Actions -->
                <template #cell(actions)="data" >
                  <div v-if="data.item.add_time === 9999 ">

                  </div>
                  <div v-else>
                    <b-link>
                      <feather-icon @click="saveItem(data.item)"
                                    icon="SaveIcon"
                                    size="16"
                                    class="align-middle text-body"
                      />
                    </b-link>
                  </div>

                </template>




              </b-table>
            </div>
          </b-card>
          </ValidationObserver>
        </template>

        <template #cell(id)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                    icon="MinusSquareIcon"
                    size="15"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==true"
            />
            <feather-icon
                    icon="PlusSquareIcon"
                    size="16"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [#{{ data.item.id }}]
        </template>

        <!-- Column: Type -->
        <template #cell(add_time)="data">
          {{toMonth((data.item.add_time))}}
        </template>

        <template #cell(actions)="data">
          <b-dropdown
                  variant="link"
                  no-caret
          >

            <template #button-content>
              <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-fixedcharge-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑明细</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>



      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <!--附件   hide-footer="true"-->
    <b-modal
            id="modal-attachments-receipt"
            centered
            ok-only
            ok-title="确认"
            size="lg"
            title="添加附件"
            ref="remarkModal"
            @ok="saveAttachments"
            @hidden="clearInput"
    >
      <b-row>

        <b-col cols="12">
          <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="附件"
                  label-for="attachments_ids"
                  label-size="sm"
                  class="mb-1"
          >
            <attachment-upload :theme="'files'"
                               :attachment_id="'attachments_ch'"
                               :id="attachmentsStr"
                               :object_type="'allot_outbound'"
                               :object_id="attachmentsObjectIdCh"
                               @change="onUploadedExcel"
            />
          </b-form-group>
        </b-col>


      </b-row>

    </b-modal>
    <b-modal
            id="modal-fixed"
            centered
            ok-only
            ok-title="确认"
            size="xl"
            title="添加主单"
            ref="fixedModal"
            @ok="addFixedCharge"
    >
      <b-row>
          <!--     平台         -->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2 required"
          >
            <xy-input-button
                    label="平台"
                    type="input"
                    v-on:change="fromChildrenAdd($event,['channel_name','channel_id'])"
                    :params="['channel_name','channel_id']"
                    :value="fixedData.channel_name"
                    :storeId="fixedData.store_id"
                    modalName="渠道方"
                    placeholder="点击选择平台 "
            >
            </xy-input-button>
          </b-col>
          <!--     店铺         -->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2 required"
          >
            <xy-input-button
                    label="店铺"
                    type="input"
                    v-on:change="fromChildrenAdd($event,['store_name','store_id'])"
                    :params="['store_name','store_id']"
                    :value="fixedData.store_name"
                    :companyId="fixedData.channel_id"
                    modalName="店铺"
                    placeholder="点击选择店铺 "
            >
            </xy-input-button>
          </b-col>

          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
          <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="日期"
                  label-for="attachments_ids"
                  label-size="sm"
                  class="mb-1 required"
          >
            <flat-pickr
                    v-model="addTime"
                    :config="{enableTime: true,dateFormat: 'Y-m',enableTime:false}"
                    class="form-control"
            />
          </b-form-group>
          </b-col>
      </b-row>

    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, onMounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  toMonth, isEmpty,addNumber,isNumber
} from '@core/utils/filter'
import fixedchargeUseList from './fixedchargeUseList'
import fixedchargeStore from './fixedchargeStore'
import XyInputButton from "@/views/components/xy/XyInputButton";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import {useToast} from "vue-toastification/composition";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
    AttachmentUpload
  },
  methods: {
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('fixedcharge/updateCondition', this.state.condition)
      this.refetchData()
    },
    clearInput: function () {
      this.attachmentsStr = ''
      this.attachmentsObjectIdCh = ''
      this.$refs['refListTable'].refresh()
    },
  },
  data(){
    return {
      attachmentsStr: '',
      attachmentsObjectIdCh: '',
      attachmentsId: '',
      orderId:'',
      fields: [
        {
          key: 'add_time',
          label: '日期',
        },
        {
          key: 'loss_charge',
          label: `服务费`,
          headerTitle:'多多买菜服务费、美团优选链路损失费、淘菜菜营销费'
        },
        {
          key: 'service_order_charge',
          label: '售后扣款',
        },
        {
          key: 'fine_charge',
          label: '罚款',
        },
        {
              key: 'other_charge',
              label: '其它扣款',
        },
        {
          key: 'share_charge',
          label: '共享仓扣款',
        },
        {
          key: 'tem_charge',
          label: '平台补款',
        },
        {
          key: 'attachments',
          label: '附件',
        },
        {
          key: 'memo',
          label: '备注',
        },
        {
          key: 'actions',
          label: '保存',
        },

      ],
      addTime:'',
      fixedData:{},
    }
  },
  setup() {
    // Register module
    if (!store.hasModule('fixedcharge')) store.registerModule('fixedcharge', fixedchargeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('fixedcharge')) store.unregisterModule('fixedcharge')
    })
    const toast = useToast()
    const state = reactive({
      condition: {},
    })

    onMounted(() => {
      state.condition = store.getters['fixedcharge/getCondition']
    })

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.state.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const fromChildrenAdd = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.fixedData[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const  exportData = function () {
      const condition = {...store.getters['fixedcharge/getCondition']}
      const params = {
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':''
      }
      Object.assign(params,condition)
      store
              .dispatch('fixedcharge/exportToExcel', params)
              .then(res => {
                toast.success("导出成功")
                let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
                let filename = "固定费用-"+new Date().getTime();
                /*-------------*/
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = URL.createObjectURL(data);
                link.setAttribute('download',filename+'.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              })
    }


    const onUploadedExcel = function (id, attachment, result) {
      this.attachmentsId = result
    }

    const showAttachments = function (item) {
      this.orderId = item.item_id
      this.attachmentsObjectIdCh = item.item_id
      this.attachmentsStr = item.attachments
      this.$refs['remarkModal'].show()
    }

    /**
     * 单独更新附件
     */
    const saveAttachments = function () {
      store.dispatch('fixedcharge/addAttachments', {
        id: this.orderId,
        attachmentsId: this.attachmentsId,
      }).then(res => {
        if (res.data.code === 0) {
          toast.success('附件更新成功')
        } else {
          toast.error('附件更新失败')
        }
      })
    }

    /**
     * 保存明细
     */
    const saveItem = async function (data) {
      let success = await this.$refs.observer.validate()
      if(success){
        store.dispatch('fixedcharge/saveItem', {
          ...data
        }).then(res => {
          if (res.data.code === 0) {
            toast.success('明细更新成功')
          } else {
            toast.error(res.data.data)
          }
          refetchData()
        })
      }else {
        toast.error('金额格式不正确!')
      }
    }

    const showFixedCharge = function () {
      this.fixedData = {};
      this.addTime = '';
      this.$refs['fixedModal'].show()
    }

    const addFixedCharge = function () {
      if(isEmpty(this.fixedData.channel_id)||isEmpty(this.fixedData.store_id)||isEmpty(this.addTime)){
        toast.error("存在未填字段!")
        return;
      }
      store.dispatch('fixedcharge/addFixedCharge', {
        channelId:this.fixedData.channel_id,
        channelName:this.fixedData.channel_name,
        storeId:this.fixedData.store_id,
        storeName:this.fixedData.store_name,
        addTime:this.addTime
      }).then(res => {
        if (res.data.code === 0) {
          toast.success('主单添加成功！')
          this.refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
    }



    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      totalAmount,

      // UI
    } = fixedchargeUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      state,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      totalAmount,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
      toMonth,
      fromChildren,
      exportData,
      onUploadedExcel,
      showAttachments,
      saveAttachments,
      saveItem,
      showFixedCharge,
      addFixedCharge,
      fromChildrenAdd
      ,addNumber,isNumber
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
